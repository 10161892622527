<template>
  <div>
    <div v-if="selectedAssetGroup._id || selectedAsset._id">
      <!-- **** -->
      <!-- TOP CARDS -->
      <div class="row mb-4">
        <div class="col">
          <ConsumptionCard
            :value="analytics['emissions-summary']?.totalEmissions?.scope1 / 1000"
            :prev-value="analytics['emissions-summary-prev']?.totalEmissions?.scope1 / 1000"
            precision="2"
            icon-class="fa-flame"
            description="Scope 1 Emissions"
            units="t CO2e"
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['emissions-summary']"
            :prev-value-loading="loadingAction.getAnalytics['emissions-summary-prev']"
            :error="errorAction.getAnalytics['emissions-summary']"
          />
        </div>

        <div class="col">
          <ConsumptionCard
            :value="analytics['emissions-summary']?.totalEmissions?.scope2 / 1000"
            :prev-value="analytics['emissions-summary-prev']?.totalEmissions?.scope2 / 1000"
            precision="2"
            icon-class="fa-plug"
            description="Scope 2 Emissions"
            units="t CO2e"
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['emissions-summary']"
            :prev-value-loading="loadingAction.getAnalytics['emissions-summary-prev']"
            :error="errorAction.getAnalytics['emissions-summary']"
          />
        </div>
        <div class="col">
          <ConsumptionCard
            :value="analytics['emissions-summary']?.totalEmissions?.scope3 / 1000"
            :prev-value="analytics['emissions-summary-prev']?.totalEmissions?.scope3 / 1000"
            precision="2"
            icon-class="fa-conveyor-belt-boxes"
            description="Scope 3 Emissions"
            units="t CO2e"
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['emissions-summary']"
            :prev-value-loading="loadingAction.getAnalytics['emissions-summary-prev']"
            :error="errorAction.getAnalytics['emissions-summary']"
          />
        </div>
        <div class="col">
          <ConsumptionCard
            :value="analytics['emissions-summary']?.totalEmissions?.total / 1000"
            :prev-value="analytics['emissions-summary-prev']?.totalEmissions?.total / 1000"
            icon-class="fa-leaf text-success"
            description="Total Emissions"
            units="t CO2e"
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['emissions-summary']"
            :prev-value-loading="loadingAction.getAnalytics['emissions-summary-prev']"
            :error="errorAction.getAnalytics['emissions-summary']"
          />
        </div>
      </div>
      <!-- TOP CARDS -->
      <!-- **** -->

      <!-- **** -->
      <!-- CHART -->
      <div class="col bg-lighter rounded-md px-4 py-3 mb-4">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Granularity</div>
            <FormItem
              id="granularity"
              v-model="analyticsOptions.granularity"
              class="mb-0"
              type="select"
              :options="[
                { label: 'Half-hourly', value: 'halfhourly' },
                { label: 'Hourly', value: 'hourly' },
                { label: 'Daily', value: 'daily' },
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
                { label: 'Quarterly', value: 'quarterly' },
                { label: 'Annually', value: 'yearly' }
              ]"
              :disabled="loadingAction.getConsumptions"
              @input="onGranularityUpdate"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Highlight</div>
            <FormItem
              id="highlight"
              v-model="options.highlight"
              class="mb-0"
              type="select"
              :options="[
                { label: 'None', value: '' },
                { label: 'Source', value: 'source' },
                { label: 'Compare To', value: 'compare' }
              ]"
              :disabled="loadingAction.getConsumptions"
              @input="refreshChart"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Graph Type</div>
            <a
              href="#"
              class="font-w600 px-3 py-1"
              :class="{ 'bg-primary text-white border rounded-md': options.chartType === 'bar' }"
              @click.prevent="options.chartType = 'bar'"
              ><i class="fa-solid fa-chart-column mr-1"></i> Bar</a
            >
            <a
              href="#"
              class="font-w600 px-3 py-1"
              :class="{ 'bg-primary text-white border rounded-md': options.chartType === 'line' }"
              @click.prevent="options.chartType = 'line'"
              ><i class="fa-solid fa-chart-line mr-1"></i> Line</a
            >
          </div>
        </div>
      </div>
      <Chart
        v-if="!loading && !loadingAction.getAnalytics['emissions-summary'] && analytics['emissions-summary']?.data?.length"
        class="mb-4"
        style="height: 500px"
        :option="emissionsChart"
      />
      <Spinner v-else class="mb-10" />
      <!-- CHART -->
      <!-- **** -->

      <!-- **** -->
      <!-- BOTTOM CARDS -->
      <div class="row">
        <div class="col-xl-4">
          <Block>
            <ChartCard
              title="Scope Breakdown"
              :loading="loadingAction.getAnalytics['emissions-summary']"
              :error="errorAction.getAnalytics['emissions-summary']"
            >
              <template>
                <div v-if="analytics[`emissions-summary`]?.totalEmissions">
                  <apexchart height="350" :options="scopeBreakdownChart.options" :series="scopeBreakdownChart.series" />
                  <div class="mb-0 mt-2 text-muted"><strong>Source:</strong> BEIS</div>
                </div>
              </template>
            </ChartCard>
          </Block>
        </div>

        <div v-if="showAssetContractRenewable" class="col-xl-4">
          <Block>
            <ChartCard
              title="Renewable Percentages"
              description="Based on average contract renewable percentages for all accounts on this asset."
              :loading="loadingAction.getAnalytics['asset-contract-renewable']"
              :error="errorAction.getAnalytics['asset-contract-renewable']"
            >
              <template>
                <div v-if="analytics['asset-contract-renewable'] && !loadingAction.getAnalytics['asset-contract-renewable']">
                  <div>
                    <div class="row align-items-center mb-4">
                      <div
                        v-if="analytics['asset-contract-renewable'].results.gas && analytics['asset-contract-renewable'].results.gas !== 'N/A'"
                        class="col-md-6"
                      >
                        <div class="d-flex align-items-center">
                          <div class="mr-3"><UtilityTypeIcon type="gas" :text="false" icon-class="fa-2x" /></div>
                          <div class="flex-grow-1">
                            <h4 class="mb-2">Gas</h4>

                            <h5 class="mb-2">
                              {{ analytics['asset-contract-renewable'].results.gas | numberFormat(2) }}%
                              <small class="text-muted">Renewable</small>
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="
                          analytics['asset-contract-renewable'].results.electricity &&
                          analytics['asset-contract-renewable'].results.electricity !== 'N/A'
                        "
                        class="col-md-6"
                      >
                        <div class="d-flex align-items-center">
                          <div class="mr-3"><UtilityTypeIcon type="electricity" :text="false" icon-class="fa-2x" /></div>
                          <div class="flex-grow-1">
                            <h4 class="mb-2">Electricity</h4>

                            <h5 class="mb-2">
                              {{ analytics['asset-contract-renewable'].results.electricity | numberFormat(2) }}%
                              <small class="text-muted">Renewable</small>
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="analytics['asset-contract-renewable'].results.water && analytics['asset-contract-renewable'].results.water !== 'N/A'"
                        class="col-md-6 mt-3"
                      >
                        <div class="d-flex align-items-center">
                          <div class="mr-3"><UtilityTypeIcon type="water" :text="false" icon-class="fa-2x" /></div>
                          <div class="flex-grow-1">
                            <h4 class="mb-2">Water</h4>

                            <h5 class="mb-2">
                              {{ analytics['asset-contract-renewable'].results.water | numberFormat(2) }}%
                              <small class="text-muted">Renewable</small>
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="analytics['asset-contract-renewable'].results.waste && analytics['asset-contract-renewable'].results.waste !== 'N/A'"
                        class="col-md-6 mt-3"
                      >
                        <div class="d-flex align-items-center">
                          <div class="mr-3"><UtilityTypeIcon type="waste" :text="false" icon-class="fa-2x" /></div>
                          <div class="flex-grow-1">
                            <h4 class="mb-2">Waste</h4>

                            <h5 class="mb-2">
                              {{ analytics['asset-contract-renewable'].results.waste | numberFormat(2) }}%
                              <small class="text-muted">Renewable</small>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="analytics['asset-contract-renewable'].results.total && analytics['asset-contract-renewable'].results.total !== 'N/A'"
                      class="h2 pt-4 border-top mb-2"
                    >
                      {{ analytics['asset-contract-renewable'].results.total | numberFormat(2) }}%
                      <small class="text-muted">Renewable (Overall avg.)</small>
                    </div>
                  </div>
                </div>
              </template>
            </ChartCard>
          </Block>
        </div>
        <div class="col-xl-4">
          <Block>
            <ChartCard title="EPC Ratings" :loading="loadingAction.getAnalytics['epc-ratings']" :error="errorAction.getAnalytics['epc-ratings']">
              <template v-slot:description
                >Total number of approved EPC ratings for this {{ analyticsOptions.type }}, grouped by its performance category.</template
              >

              <template>
                <div v-if="analytics[`epc-ratings`] && analytics[`epc-ratings`].results">
                  <apexchart :options="epcRatingsChart.options" :series="epcRatingsChart.series" />
                </div>
              </template>
            </ChartCard>
          </Block>
        </div>
      </div>
      <!-- BOTTOM CARDS -->
      <!-- **** -->

      <!-- **** -->
      <!-- OLD CARDS -->
      <!-- <div class="col-xl-4">
          <Block>
            <ChartCard
              title="Emissions Breakdown"
              :loading="loadingAction.getAnalytics['emissions-summary']"
              :error="errorAction.getAnalytics['emissions-summary']"
            >
              <template>
                <div v-if="analytics[`emissions-summary`]?.totalEmissions">
                  <apexchart height="350" :options="emissionsBreakdownChart.options" :series="emissionsBreakdownChart.series" />
                  <div class="mb-0 mt-2 text-muted"><strong>Source:</strong> {{ emissionSources.join(', ') }}</div>
                </div>
              </template>
            </ChartCard>
          </Block>
        </div> -->
      <!-- <div v-if="analyticsOptions.type === 'portfolio'" class="col-xl-4">
          <Block>
            <ChartCard
              title="Performance (Assets)"
              :loading="loadingAction.getAnalytics['emissions-summary']"
              :error="errorAction.getAnalytics['emissions-summary']"
            >
              <template v-slot:description
                >Top 10 assets sorted by emissions per floor area in descending order <strong>CO<sub>2</sub>e m<sup>2</sup></strong
                >.</template
              >

              <template v-slot:actions
                ><button class="btn btn-link font-w600 btn-sm pb-0" @click="csvExport(assetsByFloorArea)">
                  <i class="fa fa-download fa-fw" /></button
              ></template>

              <template>
                <table class="table table-borderless table-vcenter">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Site Name</th>
                      <th>Emissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in assetsByFloorArea.slice(0, 10)" :key="item.assetId">
                      <th>{{ index + 1 }}</th>
                      <td>{{ item.siteName }}</td>
                      <td>{{ item.totalByFloorArea | numberFormat(2) }}</td>
                    </tr>
                  </tbody>
                </table>
                <small class="text-muted font-w600"><i class="fa fa-warning text-warning mr-1" /> Assets with no floor area have been excluded</small>
              </template>
            </ChartCard>
          </Block>
        </div> -->
      <!-- <div class="col-xl-4">
          <Block>
            <ChartCard
              title="Performance (Accounts)"
              :loading="loadingAction.getAnalytics['emissions-summary']"
              :error="errorAction.getAnalytics['emissions-summary']"
            >
              <template v-slot:description
                >Top 10 accounts sorted by emissions per floor area in descending order <strong>CO<sub>2</sub>e m<sup>2</sup></strong
                >.</template
              >

              <template v-slot:actions
                ><button class="btn btn-link font-w600 btn-sm pb-0" @click="csvExport(assetsByFloorArea)">
                  <i class="fa fa-download fa-fw" /></button
              ></template>

              <template>
                <table class="table table-borderless table-vcenter">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Account Name</th>
                      <th>Emissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in accountsByFloorArea.slice(0, 10)" :key="item.accountId">
                      <th>{{ index + 1 }}</th>
                      <td>
                        <div class="d-flex align-items-center">
                          <UtilityTypeIcon :type="item.type" :text="false" :large="true" class="mr-3" />
                          <div>
                            <div class="font-w600">{{ item.name }}</div>
                            <div>{{ item.siteName }}</div>
                          </div>
                        </div>
                      </td>
                      <td>{{ item.totalByFloorArea | numberFormat(2) }}</td>
                    </tr>
                  </tbody>
                </table>
                <small class="text-muted font-w600"
                  ><i class="fa fa-warning text-warning mr-1" /> Accounts with no floor area have been excluded</small
                >
              </template>
            </ChartCard>
          </Block>
        </div> -->
      <!-- OLD CARDS -->
      <!-- **** -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

import { capitalize } from '@/lib/helpers';
import { chartConsumptionOptions, granularityFormatMap } from '../../lib/consumption';

import Block from '@/components/analytics/Block';
import Chart from '@/components/echarts/Chart';
import ChartCard from '@/components/analytics/ChartCard';
import ConsumptionCard from '@/components/analytics/ConsumptionCard';
import FormItem from '@/components/FormItem';
import Spinner from '@/components/Spinner';
import UtilityTypeIcon from '@/components/UtilityTypeIcon';

export default {
  name: 'AnalyticsEmissions',
  components: {
    Block,
    Chart,
    ChartCard,
    ConsumptionCard,
    FormItem,
    Spinner,
    UtilityTypeIcon
  },
  data() {
    return {
      loading: false,
      options: {
        accountId: '',
        accountType: 'electricity',
        chartType: 'bar',
        highlight: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      analytics: 'analytics/analytics',
      analyticsOptions: 'analytics/options',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      selectedAsset: 'analytics/selectedAsset',
      selectedAssetGroup: 'analytics/selectedAssetGroup'
    }),
    assetsByFloorArea() {
      if (!this.analytics['emissions-summary']?.results?.assetLeaderboard) return [];

      return this.analytics['emissions-summary'].results.assetLeaderboard;
    },
    accountsByFloorArea() {
      if (!this.analytics['emissions-summary']?.results?.accountLeaderboard) return [];

      return this.analytics['emissions-summary'].results.accountLeaderboard;
    },
    epcRatingsChart() {
      return {
        series: [
          {
            name: 'Ratings',
            data: Object.values(this.analytics['epc-ratings']?.results || []).map(v => v),
            step: 1
          }
        ],
        options: {
          chart: {
            type: 'bar'
          },
          title: this.defaultChartOptions.title,
          colors: this.defaultChartOptions.colors,
          labels: Object.keys(this.analytics['epc-ratings']?.results || []).map(v => v),
          legend: {
            enabled: false
          },
          yaxis: {
            labels: {
              formatter: v => v.toFixed(0)
            }
          }
        }
      };
    },
    emissionsChart() {
      const data = (this.analytics['emissions-summary']?.data || []).map(c => ({
        ...c,
        date: this.analyticsOptions.granularity === 'quarterly' ? moment(c.date).add(1, 'month').toDate() : moment(c.date).toDate()
      }));

      const baseOptions = {};

      const baseSeriesOptions = {
        type: this.options.chartType
      };

      if (this.options.chartType === 'line') {
        baseSeriesOptions.smooth = false;
      }

      const totalEmissionSeries = {
        ...baseSeriesOptions,
        name: 'Emission',
        data: data.map(m => [m.date, m.emissions.total / 1000])
      };

      const prevEmissionSeries = {
        ...baseSeriesOptions,
        name: 'Prev Period',
        data: (this.analytics['emissions-summary-prev']?.data || []).map((m, index) => {
          const date = data[index]?.date;
          return [date, m.emissions.total / 1000];
        })
      };

      const scope1Series = {
        ...baseSeriesOptions,
        name: 'Scope 1',
        data: data.map(m => {
          const invoiceA = m.emissions?.scope1 || null;
          return [m.date, invoiceA / 1000];
        })
      };

      const scope2Series = {
        ...baseSeriesOptions,
        name: 'Scope 2',
        data: data.map(m => {
          const hh = m.emissions?.scope2 || null;
          return [m.date, hh / 1000];
        })
      };

      const scope3Series = {
        ...baseSeriesOptions,
        name: 'Scope 3',
        data: data.map(m => {
          const hh = m.emissions?.scope3 || null;
          return [m.date, hh / 1000];
        })
      };

      let series = [];

      if (this.options.highlight === 'source') {
        series = [
          { ...scope1Series, stack: this.options.chartType === 'bar' ? 'a' : null },
          { ...scope2Series, stack: this.options.chartType === 'bar' ? 'a' : null },
          { ...scope3Series, stack: this.options.chartType === 'bar' ? 'a' : null }
        ];
        baseOptions.legend = {};
      } else if (this.options.highlight === 'compare') {
        series = [totalEmissionSeries, prevEmissionSeries];
      } else {
        series = [totalEmissionSeries];
      }

      return {
        ...chartConsumptionOptions,
        ...baseOptions,
        legend: {},
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            const s = params
              .map(param => {
                const series = param.seriesName.toLocaleString();
                const value = param.value[1] !== null ? param.value[1].toLocaleString() : null;
                const valueHtml = value ? `<strong>${value}</strong> t CO2e` : '<strong>No data</strong>';
                return `<i class="fa fa-circle fa-fw fa-sm mr-1" style="color: ${param.color}"></i>${series}: ${valueHtml}`;
              })
              .join('<br />');

            const date = moment.utc(params[0].axisValue).format(granularityFormatMap[this.analyticsOptions.granularity].format);

            return `<strong>${date}</strong><br /><br />${s}`;
          },
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          minInterval: granularityFormatMap[this.analyticsOptions.granularity].minTick
        },
        yAxis: {
          name: 't CO2e',
          type: 'value'
        },
        series
      };
    },
    emissionsBreakdownChart() {
      const values = (this.analytics['emissions-summary']?.data || []).map(item => item.emissions.total);

      const isShowMeterUser = this.analytics['emissions-summary']?.results?.breakdown.some(item => item.meterUser === 'tenant');
      return {
        series: values,
        options: {
          chart: {
            type: 'pie'
          },
          title: this.defaultChartOptions.title,
          colors: this.defaultChartOptions.colors,
          labels: (this.analytics['emissions-summary']?.results?.breakdown || []).map(
            item => `${item.category}${isShowMeterUser ? ` (${capitalize(item.meterUser)})` : ''}`
          ),
          legend: {
            position: 'bottom'
          },
          fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: undefined
          },
          dataLabels: {
            enabled: false
          }
        }
      };
    },
    scopeBreakdownChart() {
      const { scope1, scope2, scope3 } = this.analytics[`emissions-summary`]?.totalEmissions;

      return {
        series: [scope1 / 1000, scope2 / 1000, scope3 / 1000],
        options: {
          chart: {
            type: 'pie'
          },
          title: this.defaultChartOptions.title,
          colors: this.defaultChartOptions.colors,
          labels: ['Scope 1', 'Scope 2', 'Scope 3'],
          legend: {
            position: 'bottom'
          },
          fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: undefined
          },
          dataLabels: {
            enabled: false
          }
        }
      };
    },
    defaultChartOptions() {
      return {
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Label'
          },
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: {point.y}'
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        },
        colors: ['#65C198', '#1F303D', '#F25F5C', '#FFE066', '#1B98E0', '#727272', '#db6edc', '#945ab9', '#25714e', '#941d1c', '#977911', '#045889'],
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                plotOptions: {
                  series: {
                    marker: {
                      radius: 2.5
                    }
                  }
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      };
    },
    showAssetContractRenewable() {
      const results = this.analytics['asset-contract-renewable']?.results;
      if (!results) return false;

      return Object.values(results).some(v => v !== 'N/A');
    }
  },
  methods: {
    ...mapActions({
      refresh: 'analytics/getAssetEmissions'
    }),
    ...mapMutations({
      setOption: 'analytics/SET_OPTION'
    }),
    refreshChart() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    onGranularityUpdate(granularity) {
      this.setOption({ key: 'granularity', option: granularity });
      this.refresh();
    },
    capitalize,
    csvExport(arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += [Object.keys(arrData[0]).join(';'), ...arrData.map(item => Object.values(item).join(';'))]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'export.csv');
      link.click();
    }
  }
};
</script>
